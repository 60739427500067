import Vue from 'vue';
import Vuex from "vuex"
import mutations from './mutation';
import MultiDataFrom from './modules/MultiDataForm'
import NewBaseIlm from './modules/newBaseIlm'
import actions from './action'
Vue.use(Vuex)
const store = new Vuex.Store({
    strict: false,
    modules: {
        multi_data_from: MultiDataFrom,
        nbs: NewBaseIlm
    },
    state: {
        auth: {
            auth: false,
            token: '',
            token_refresh: '',
            name: '',
            email: '',
            type: '',
            username: '',
            keyclock: '',
        },
        claim_chart_data: {},
        ratings: {
            "LOSSASSRMK": '',
            "TYPESOFREPORTRMK": '',
            "SURVEYREPORTSRMK": '',
            "DOCUMENTATIONRMK": '',
            "CLAIMSERVINGRMK": ''
        },
        ratingRemarks: {
            "LOSSASSRMK": '',
            "TYPESOFREPORTRMK": '',
            "SURVEYREPORTSRMK": '',
            "DOCUMENTATIONRMK": '',
            "CLAIMSERVINGRMK": ''
        },
        Overallrating: '',
        RatingsData: {},
        claim_chart_data_two: {},
        claim_chart_data_three: {},
        claim_chart_data_four: {},
        profile_update: {},
        claim_cycle_datas_second: [],
        claim_cycle_datas_first: [],
        claim_details: {},
        current_menu: {},
        lookupby_type: [],
        all_page_feilds: [],
        recent_notification: [],
        look_by_type_lists: [],
        isPageReload: false,
        permissions: [],
        notification_count: 0,
        active_section: {},
        all_notification: [],
        request_failure: {
            status: false,
            message: '',
            title: '',
        },
        saved_details: '',
        saved_remarks: '',
        menuPages: [],
        widgetPages: [],
        subMenus: [],
        case_status: [],
        userNameArray: [],
        active_form: 'login',
        ilm_pro_type: {},
        show_app_type: false,
        report_submit_details: [],
        side_menu_bar: [],
        show_breadcrumb: false,
        report_sidebar: [],
        report_sidebar_for_status: [],
        dashboard_status_count: [],
        investigation_history: [],
        loader: false,
        popup_page_data_details: {},
        popup_page_data: {},
        profile: {},
        section_pages: [],
        table_add_form_data: {},
        case_filter_form: {},
        doc_list: [],
        dashbaord_doc_list: [],
        uploaded_document: [],
        dashboard_hiererachy: [],
        page_fields: {
            datas: [],
            page_name: '',
            display_type: 'text'
        },
        multi_section_page_fields: {
            datas: [],
            page_name: '',
            subSectionCode: '',
            type: '',
        },
        dashbaord_data: {
            label: [],
            data: []

        },
        buttonGroupCode: {},
        serverTableData: {},
        insured_form_value: {},
        multi_section_form_data: {},
        multi_section_form_data_init: {},
        tabel_form_data: {},
        multi_section_pannel_form_data: [],
        multi_section_popup_form_data: [],
        popup_pannel_form_data: {},
        pop_section_pannel_form_data: [],
        allowed_actions: [],
        pop_allowed_actions: [],
        isSelected: false,
        tabel_filter_result: {},
        active_page: {},
        active_menu: {},
        claim_ref_number: '',
        area_list: [],
        table_data: [],
        flash_message: [],
        current_page_menu: {},
        communication_list: {},
        surveyDetails: {},
        popup_form_data: {},
        user_details: {
            "activeUser": 0,
            "inActiveUser": 0,
            "totalUser": 0,
        },
        summary_details: {
            "noOfClaimRegsitered": 0,
            "noOfClaimUploaded": 0,
            "noOfClaimUpdated": 0,
        },
        display_menu: false,
        ShowTableData: false,
        fetchDocumentData: {},
        phiPartnerId: '',
        claimProcessId: '',
        currentStepId: ''

    },
    mutations,
    actions
});
export default store;